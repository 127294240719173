import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './trans.css';
import Welcome from './components/Welcomefiles/Welcome';
import { fetchuseauthorization } from './components/API/Login_API';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import tabexpreload from './components/Dashboard/Generalfiles/images/tabexpreload.mp4';
import PrintComponent from './components/Dashboard/Ordersfiles/PrintComponent.js';
import Login from './components/Dashboard/Login/Login.js';
import generalstyles from './components/Dashboard/Generalfiles/CSS_GENERAL/general.module.css';
import { IoMdClose } from 'react-icons/io';
import { Modal } from 'react-bootstrap';

const Dashboardrouter = React.lazy(() => import('./components/Dashboard/Dashboardrouter'));

const App = (props) => {
    const [nointernetconnection, setnointernetconnection] = useState(false);
    window.addEventListener('offline', function (e) {
        setnointernetconnection(true);
    });

    window.addEventListener('online', function (e) {
        setnointernetconnection(false);
    });
    let history = useHistory();
    const {
        userloggedinfobjcontext,
        setuserloggedinfobjcontext,
        isloggedincontext,
        setisloggedincontext,
        useraccesspagescontext,
        setuseraccesspagescontext,
        surveyModalOpenContext,
        setsurveyModalOpenContext,
    } = React.useContext(Loggedincontext);
    const fetchuseauthorizationQuery = useQuery(['fetchuseauthorization'], () => fetchuseauthorization(), { keepPreviousData: true, staleTime: 500000000000000000 });
    // useEffect(() => {
    // }, []);

    useEffect(() => {
        if (fetchuseauthorizationQuery.isSuccess) {
            setisloggedincontext(fetchuseauthorizationQuery.data.data.loggedin);
            if (fetchuseauthorizationQuery.data.data.loggedin) {
                var tempuserloggedinfo = { ...userloggedinfobjcontext };
                tempuserloggedinfo.userinfo = { ...fetchuseauthorizationQuery.data.data.userinfo };
                tempuserloggedinfo.currentinstinfo = { ...fetchuseauthorizationQuery.data.data.instinfo };
                if (fetchuseauthorizationQuery.data.data.inst_should_fill_survey_form == true) {
                    setsurveyModalOpenContext(true);
                }
                setuserloggedinfobjcontext({
                    ...tempuserloggedinfo,
                });
                if (Array.isArray(fetchuseauthorizationQuery.data.data.userpagepermissions)) {
                    setuseraccesspagescontext([...fetchuseauthorizationQuery.data.data.userpagepermissions]);
                } else {
                    setuseraccesspagescontext([]);
                }
            } else {
                // window.location.href = 'https://tabex-co.com';
            }
        }
    }, [fetchuseauthorizationQuery.isSuccess]);

    return (
        <Router>
            <Routedispatcherprovider>
                <div style={{ height: '100%', width: '100%' }}>
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    backgroundColor: 'white',
                                }}
                                class="row w-100 m-0 d-flex justify-content-center"
                            >
                                <div class="col-lg-12 d-flex justify-content-center align-items-center">
                                    <video src={tabexpreload} autoPlay="true" width="100%" height="500" muted />
                                </div>
                            </div>
                        }
                    >
                        <Route
                            render={({ location, match }) => {
                                return (
                                    <div>
                                        {nointernetconnection && (
                                            <div
                                                class="w-100 text-center h-100 d-flex mx-auto justify-content-center"
                                                style={{
                                                    backgroundColor: 'white',
                                                    position: 'fixed',
                                                    height: '100%',
                                                    zIndex: 99999999999,
                                                    color: 'red',
                                                    opacity: 0.5,
                                                    alignContent: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'center',
                                                    fontSize: '25px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <div class="text-center  mt-auto mb-auto m-auto align-items-center d-flex">
                                                    Trying To Connect To The Internet...
                                                    <CircularProgress color="red" width="30px" height="30px" />
                                                </div>
                                            </div>
                                        )}
                                        <TransitionGroup>
                                            <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                <Switch key={location.key} location={location}>
                                                    <Route
                                                        exact
                                                        path="/"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <Redirect to="/dashboard/institute" />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/login"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <Redirect to="/dashboard/institute" />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/dashboard"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <Redirect to="/dashboard/institute" />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route
                                                        path="/dashboard/institute"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <Dashboardrouter />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route
                                                        path="/dashboard/printcomponent/:orderidparams"
                                                        render={() => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <PrintComponent />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    <Route
                                                        path="/dashboard/welcome"
                                                        render={(props) => {
                                                            if (fetchuseauthorizationQuery.isSuccess) {
                                                                if (isloggedincontext == true) {
                                                                    return <Welcome />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Switch>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    </div>
                                );
                            }}
                        />
                    </Suspense>
                </div>
            </Routedispatcherprovider>
        </Router>
    );
};

export default App;
