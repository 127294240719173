import axios from 'axios';
import { serverbaselink } from '../../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const fetch_orders = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchorders',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const add_order = (axiosdata) => {
    // customerid: Fixed Variables: "allorders","filter"
    // itemsarray: array of objects that has productid,isvariant,variantid
    // fullfilmstatus: ['Fulfilled','Unfulfilled','Partially Fulfilled'] send any of these
    // paidstatus: ['Paid','Unpaid'] send any of these
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/addorder',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const crud_order = (axiosdata) => {
    // functype	enum	Fixed Variables: "editstatus","additemtoorder","removeitemfromorder","deleteorder"
    // orderid	text	used in all functypes.
    // fullfilmstatus	enum	enum:['Fulfilled','Unfulfilled','Partially Fulfilled'] send any of these when functype is editstatus
    // paidstatus	enum	enum:['Paid','Unpaid'] send any of these when functype is editstatus
    // productid	text	when functype is additemtoorder
    // isvariant	text	when functype is additemtoorder
    // variantid	text	when functype is additemtoorder
    // orderitemid	text	when functype is removeitemfromorder and you can find it in get orders array.
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ordercrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetch_order_information = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchorderinformation',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Apply_PromoCode_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ApplyPromoCode',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchOrderStatus_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchOrderStatus',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const OrderSearch_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/OrderSearch',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchOrdersRecentActivity_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchOrdersRecentActivity',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Fetch_Order_Items_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/Fetch_Order_Items',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AddPOSorder_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/AddPOSorder',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const userAssignOrder_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/userassignorder',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FetchCustomerCarts_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchCustomerCarts',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FetchCustomerCartInfo_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchCustomerCartInfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchinsttables_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchinsttables',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const crudinsttables_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/crudinsttables',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
