import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import '../Generalfiles/CSS_GENERAL/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import tabexlogo from '../Generalfiles/images/tabexlogo.png';
import egyptflag from '../Generalfiles/images/egyptflag.png';
import usflag from '../Generalfiles/images/usflag.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiCog, BiSupport } from 'react-icons/bi';
import noimage from '../Generalfiles/images/noimage.png';
import { CgMenuLeftAlt, CgMenuRightAlt } from 'react-icons/cg';
import { LanguageContext } from '../../../LanguageContext';
import { Loggedincontext } from '../../../Loggedincontext.js';
import { Routedispatcher } from '../../../Routedispatcher.js';
import headerstyles from './header.module.css';
import '../Generalfiles/CSS_GENERAL/dropdown.css';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { logout } from '../../API/Login_API';
import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import sidenavstyles from '../Sidenavfiles/sidenav.module.css';
import { serverbaselink, imagebaselink } from '../../../Env_Variables';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import formstyles from '../Support/form.module.css';

const Header = (props) => {
    let history = useHistory();
    const { lang, setlang, langdetect } = React.useContext(LanguageContext);
    const { setisloggedincontext, userloggedinfobjcontext } = React.useContext(Loggedincontext);
    const { routedispatchercontext } = React.useContext(Routedispatcher);
    const { pagetitle_context } = React.useContext(Contexthandlerscontext);
    const [langdetecttrans, setlangdetecttrans] = useState('');
    let location = useLocation();

    useEffect(() => {
        if (userloggedinfobjcontext?.currentinstinfo?.subscriptiondetails?.isinstactive != undefined) {
            if (
                location.pathname == '/dashboard/institute/subscription' ||
                location.pathname == '/dashboard/institute/upgradingsubscription' ||
                location.pathname == '/dashboard/institute/paymentresponse' ||
                location.pathname == '/dashboard/institute/generalsettings' ||
                location.pathname == '/dashboard/welcome'
            ) {
            } else {
                // alert(userloggedinfobjcontext?.currentinstinfo?.subscriptiondetails?.isinstactive);
                if (userloggedinfobjcontext?.currentinstinfo?.subscriptiondetails?.isinstactive == true) {
                    // alert('ss');
                } else {
                    // alert('dd');
                    history.push('/dashboard/institute/subscription');
                }
            }
        }
    }, [location, userloggedinfobjcontext]);
    useEffect(() => {
        if (langdetect == 'en') {
            setlangdetecttrans('en');
        } else {
            setlangdetecttrans('ع');
        }
    }, []);
    const LogoutMutation = useMutation('logout', {
        mutationFn: logout,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('Error', lang.errorinloggingout);
        },
        onSuccess: (data, variables, context) => {
            window.location.href = '/';
        },
    });
    return (
        <div class="row m-0 w-100">
            <div class={` ${headerstyles.header_container}` + ' d-lg-flex d-md-none '}>
                <div class="row w-100 m-0 d-flex align-items-center h-100">
                    <div class="col-xl-6 col-lg-6 p-0 d-flex align-items-center h-100">
                        <div class={headerstyles.widget_heading + ' d-flex align-items-center justify-content-start '} style={{ cursor: 'default' }}>
                            <span class={headerstyles.widget_username} style={{ cursor: 'default' }}>
                                {pagetitle_context}
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 p-0 d-flex align-items-center justify-content-end h-100">
                        <ul class={` ${headerstyles.header_menu}` + ' h-100 m-0 p-0 d-flex align-items-center justify-content-end '}>
                            <button
                                style={{ marginInlineEnd: '15px' }}
                                onClick={() => {
                                    history.push('/dashboard/institute/support?type=tickets');
                                }}
                                class={formstyles.primarybutton + ' allcentered'}
                            >
                                <BiSupport class={langdetect == 'en' ? 'mr-2 mb-1' : 'ml-2 mb-1'} />
                                <>{lang.support}</>
                            </button>
                            <li class={'d-flex'}>
                                <Dropdown class="d-flex">
                                    <Dropdown.Toggle>
                                        <div class="d-flex align-items-center">
                                            <div
                                                class={
                                                    langdetect == 'en'
                                                        ? headerstyles.widget_heading_sidenav + ' d-flex text-center align-items-center justify-content-center mr-2'
                                                        : headerstyles.widget_heading_sidenav + ' d-flex text-center align-items-center justify-content-center ml-2 '
                                                }
                                            >
                                                <span class={headerstyles.widgetheading_greeting_sidenav}>
                                                    {lang.hello}
                                                    {langdetect == 'en' ? ',' : '،'}
                                                </span>
                                                <p className={`${headerstyles.widget_username_sidenav}` + ' text-capitalize mb-0 ml-1 mr-1 '}>{userloggedinfobjcontext.userinfo.name}</p>
                                            </div>
                                            <div class={headerstyles.header_image_container}>
                                                {userloggedinfobjcontext.currentinstinfo.instlogo != '' && (
                                                    <img src={imagebaselink + userloggedinfobjcontext.currentinstinfo.instlogo} style={{ objectFit: 'contain' }} class="" alt="Store Logo" />
                                                )}
                                                {userloggedinfobjcontext.currentinstinfo.instlogo == '' && <img src={noimage} class="" alt="Store Logo" />}
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                routedispatchercontext('/dashboard/institute/generalsettings');
                                            }}
                                        >
                                            <span class="d-flex font_13 font-weight-500 text-black text-secondaryhover align-items-center">
                                                <i class={langdetect == 'en' ? 'fa fa-cog mr-2' : 'fa fa-cog mr-0 ml-2'} style={{ opacity: 0.3 }}></i>
                                                {lang.sidenav_generalsettings}
                                            </span>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item
                                            onClick={() => {
                                                routedispatchercontext('/dashboard/welcome');
                                            }}
                                        >
                                            <span class="d-flex font_13 font-weight-500 text-black text-secondaryhover align-items-center">
                                                <i class={langdetect == 'en' ? 'fa fa-cogs mr-2' : 'fa fa-cogs mr-0 ml-2'} style={{ opacity: 0.3 }}></i>
                                                {lang.institutes}
                                            </span>
                                        </Dropdown.Item> */}
                                        <Dropdown.Item
                                            onClick={() => {
                                                LogoutMutation.mutate();
                                            }}
                                        >
                                            <span class="d-flex font_13 font-weight-500 text-black text-secondaryhover align-items-center">
                                                <i class={langdetect == 'en' ? 'fa fa-sign-out-alt mr-2' : 'fa fa-sign-out-alt mr-0 ml-2'} style={{ opacity: 0.3 }}></i>
                                                {lang.logout}
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <div className={langdetect == 'en' ? generalstyles.verical_divider + ' mr-2 ml-3 mt-auto mb-auto ' : generalstyles.verical_divider + ' ml-2 mr-3 mt-auto mb-auto '}></div>
                            <li class={langdetect == 'en' ? '' : 'dropdown-container-translated'}>
                                <a
                                    class={`${headerstyles.header_menu_item} ${headerstyles.language_cont}` + ' m-0 pr-0 d-flex  '}
                                    onClick={() => {
                                        if (langdetect == 'en') {
                                            setlang('ar');
                                        } else {
                                            setlang('en');
                                        }
                                        window.location.reload();
                                    }}
                                >
                                    {langdetect == 'en' && (
                                        <span class="text-secondaryhovercontainer">
                                            <img src={egyptflag} style={{ width: '2vh', height: '2vh' }} class={langdetect == 'en' ? 'mr-2' : 'ml-2'} />
                                            <span class={' text-capitalize font-weight-400 font_12 text-secondaryhover'}>ع</span>
                                        </span>
                                    )}
                                    {langdetect == 'ar' && (
                                        <span class="text-secondaryhovercontainer">
                                            <img src={usflag} style={{ width: '2vh', height: '2vh' }} class={langdetect == 'en' ? 'mr-2' : 'ml-2'} />
                                            <span class={' text-capitalize font-weight-400 font_12 text-secondaryhover'}>en</span>
                                        </span>
                                    )}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class={headerstyles.header_container + ' d-lg-none d-md-flex pl-3 pr-3 '}>
                <div class="row ml-0 mr-0 mt-2 w-100">
                    <div class="col-lg-6 col-md-6 col-sm-10 d-flex p-0 d-flex align-items-center">
                        <button
                            type="button"
                            class={headerstyles.hamburger}
                            id={'burgericon'}
                            onClick={() => {
                                var element = document.getElementById('sidenavContainer');
                                element.classList.add(sidenavstyles.sidebar_mobile_open);
                            }}
                        >
                            {langdetect == 'en' ? (
                                <span class="h-100 d-flex align-items-center justify-content-start">
                                    <CgMenuLeftAlt />
                                </span>
                            ) : (
                                <span class="h-100 d-flex align-items-center justify-content-start">
                                    <CgMenuRightAlt />
                                </span>
                            )}
                        </button>
                        {userloggedinfobjcontext.userinfo.currentinstactive != '621fbdd302691' && (
                            <div
                                class={`${headerstyles.header_container__logo}` + ' d-flex justify-content-start cursor-pointer '}
                                onClick={() => {
                                    history.push('/dashboard/institute');
                                }}
                                style={{ width: '70%' }}
                            >
                                <img src={tabexlogo} class=" cursor-pointer " />
                            </div>
                        )}
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-2 p-0 d-flex align-items-center justify-content-start">
                        <div class={langdetect == 'en' ? 'ml-auto' : ' dropdown-container-translated mr-auto'}>
                            <a
                                class={`${headerstyles.header_menu_item} ${headerstyles.language_cont}` + ' m-0 pr-0 d-flex  '}
                                onClick={() => {
                                    if (langdetect == 'en') {
                                        setlang('ar');
                                    } else {
                                        setlang('en');
                                    }
                                    window.location.reload();
                                }}
                            >
                                {langdetect == 'en' && (
                                    <span class="text-secondaryhovercontainer">
                                        <img src={egyptflag} style={{ width: '2vh', height: '2vh' }} class={langdetect == 'en' ? 'mr-2' : 'ml-2'} />
                                        <span class={' text-capitalize font-weight-400 font_12 text-secondaryhover'}>ع</span>
                                    </span>
                                )}
                                {langdetect == 'ar' && (
                                    <span class="text-secondaryhovercontainer">
                                        <img src={usflag} style={{ width: '2vh', height: '2vh' }} class={langdetect == 'en' ? 'mr-2' : 'ml-2'} />
                                        <span class={' text-capitalize font-weight-400 font_12 text-secondaryhover'}>en</span>
                                    </span>
                                )}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
