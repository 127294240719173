import React, { useContext, useEffect, useRef, useState } from 'react';
// import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { useQuery } from 'react-query';

import ReceiptPrintcomponent from './ReceiptPrintcomponent';
import { fetch_order_information } from '../../API/Orders_API';

const PrintComponent = (props) => {
    const { orderidparams } = useParams();

    const printRef = useRef();
    const componentRef = useRef();
    const [orderid, setorderid] = useState('');

    const fetchorderinfo = useQuery(
        ['fetch_order_information' + orderidparams],
        () =>
            fetch_order_information({
                orderid: orderidparams,
            }),
        { keepPreviousData: true, staleTime: 500000, enabled: orderidparams?.length != 0 && orderidparams != undefined ? true : false },
    );

    useEffect(() => {
           if(fetchorderinfo?.isSuccess){
        setTimeout(() => {
            if (printRef.current != undefined) {
                printRef.current.click();
            }
        }, 1500);
           }
    }, [printRef, printRef.current, fetchorderinfo?.isSuccess]);
    return (
        <div style={{ position: 'absolute', width: '100vh', height: '100vh', background: 'white', zIndex: 1000000000 }} class="row m-0 d-flex justify-content-center w-100">
            <div class="col-lg-6 p-0 d-flex justify-content-center">
                <ReceiptPrintcomponent ref={componentRef} orderitem={fetchorderinfo?.data?.data} />
            </div>
            <div class="col-lg-6 d-none p-0 justify-content-center pt-5">
                <ReactToPrint trigger={() => <button ref={printRef}>Print this </button>} content={() => componentRef.current} />
            </div>
        </div>
    );
};
export default PrintComponent;
