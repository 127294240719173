import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';

import { RiErrorWarningLine } from 'react-icons/ri';
import { fetchuseauthorization } from '../../API/Login_API.js';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { serverbaselink } from '../../../Env_Variables';
import { LanguageContext } from '../../../LanguageContext';
import { Loggedincontext } from '../../../Loggedincontext.js';
import { Routedispatcher } from '../../../Routedispatcher.js';
import { logincheck } from '../../API/Login_API';
import formstyles from './form.module.css';
import generalstyles from './general.module.css';
import { imagebaselink } from '../../../Env_Variables';

const Login = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const fetchuseauthorizationQuery = useQuery(['fetchuseauthorization'], () => fetchuseauthorization(), { keepPreviousData: true, staleTime: 500000000000000000 });

    const { lang, langdetect } = React.useContext(LanguageContext);
    let history = useHistory();
    const [logininputs, setlogininputs] = useState({
        email: '',
        password: '',
        loginstatus: true,
    });
    const [hidesignup, sethidesignup] = useState(false);
    const [buttonClicked, setbuttonClicked] = useState(false);
    const { routedispatchercontext } = React.useContext(Routedispatcher);

    const logincheckMutation = useMutation('logincheck', {
        mutationFn: logincheck,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            // NotificationManager.error('Error', 'Error In Searching users.');
        },
        onSuccess: (data, variables, context) => {
            var logininputstemp = { ...logininputs };
            logininputstemp.loginstatus = data.data.loggedin;
            setlogininputs({ ...logininputstemp });
            if (data.data.loggedin) {
                window.location.reload();
                // document.getElementById('loginform').submit();
            }
            // fetchuseauthorizationQuery.refetch();
        },
    });

    return (
        <div class="row m-0 w-100 d-flex align-items-center justify-content-center pl-sm-3 pr-sm-3 mt-md-2 pb-5 mb-3 pt-sm-4" style={{ paddingLeft: '50px', paddingRight: '50px', height: '100vh' }}>
            <img src={imagebaselink + '/tabexserieswebsiteasset/bg.png'} class={`${formstyles.formmiddleimage}` + ' d-flex d-md-none '} />
            <img src={imagebaselink + '/tabexserieswebsiteasset/bg.png'} class={`${formstyles.formupperimage}` + ' d-flex d-md-none '} />

            <div class="row m-0 w-100 d-none d-md-flex">
                <div class="col-lg-12 text-center mb-3 mb-md-0 p-0">
                    <p class="m-0 poppinsbold p-0" style={{ fontSize: '30px' }}>
                        {lang.welcomeback}
                    </p>
                </div>
                <div class="col-lg-12 text-center mb-3 mb-md-0 p-0">
                    <p class="m-0 poppinsbold p-0" style={{ fontSize: '30px' }}>
                        {lang.totabexseries}
                    </p>
                </div>
            </div>
            <div class="col-md-8 col-sm-12 d-none d-md-flex justify-content-center align-items-center mb-4" data-aos="fade-left" data-aos-easing="ease-in-out">
                <img src={imagebaselink + '/tabexserieswebsiteasset/signupasset2.png'} style={{ width: '100%' }}></img>
            </div>
            <div class="row m-0 w-100 mt-4 pt-2 pb-2 mt-md-0" style={{ zIndex: 2, overflow: 'hidden', border: '2px solid #e6e6e6', borderRadius: '20px', position: 'relative' }}>
                <div class={'col-lg-6 col-md-12 p-0 mt-md-0 pl-3 pr-3 pt-4'} data-aos="fade-right" data-aos-easing="ease-in-out" style={{ background: langdetect == 'en' ? 'white' : '' }}>
                    <div class="row m-0 w-100 pl-4 pr-4 pl-sm-0 pr-sm-0">
                        <div class="col-lg-12 d-flex justify-content-center d-md-none">
                            <p class="m-0 p-0 poppinsbold text-md-center" style={{ fontSize: '24px' }}>
                                {lang.welcomeback} {lang.totabexseries}
                            </p>
                        </div>
                        <div class="col-lg-12 p-0">
                            <form method="POST" action={serverbaselink + '/surfer/login'} class="d-flex flex-column align-items-center mt-md-0" id={'loginform'}>
                                <div class="row m-0 w-100 allcentered pt-4">
                                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start">
                                        <div class="row m-0 w-100">
                                            <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                <label for="email" class={formstyles.form__label}>
                                                    {lang.email}
                                                </label>
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    class={formstyles.form__field}
                                                    placeholder={lang.email}
                                                    value={logininputs.email}
                                                    onChange={(event) => {
                                                        var logininputstemp = { ...logininputs };
                                                        logininputstemp.email = event.target.value;
                                                        setlogininputs({ ...logininputstemp });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-start mb-5">
                                        <div class="row m-0 w-100">
                                            <div class={`${formstyles.form__group} ${formstyles.field}`}>
                                                <label for="password" class={formstyles.form__label}>
                                                    {lang.password}
                                                </label>
                                                <input
                                                    name="password"
                                                    id="password"
                                                    type="password"
                                                    class={formstyles.form__field}
                                                    placeholder={lang.password}
                                                    value={logininputs.password}
                                                    onChange={(event) => {
                                                        var logininputstemp = { ...logininputs };
                                                        logininputstemp.password = event.target.value;
                                                        setlogininputs({ ...logininputstemp });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 p-0 d-none justify-content-end mb-3">
                                        <Link to="/forgetpassword">
                                            <p className={'text-light text-secondayhover mt-1 mr-2 mb-0 align-items-center poppinslight font-13'} style={{ textDecoration: 'underline' }}>
                                                {lang.forgotpassword}
                                            </p>
                                        </Link>
                                    </div>
                                    {!logininputs.loginstatus && (
                                        <p class={'font-14 text-danger mt-4 allcentered '}>
                                            <RiErrorWarningLine class="ml-1 mr-1" />
                                            {lang.wrongemailorpassword}
                                        </p>
                                    )}
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <button
                                            class={
                                                `${generalstyles.btn} ${generalstyles.btn_info} ${formstyles.btn_form}` +
                                                ' d-flex align-items-center justify-content-center m-auto poppinslight w-sm-100 '
                                            }
                                            style={{ letterSpacing: langdetect == 'en' ? '1px' : 0 }}
                                            type={'button'}
                                            disabled={logincheckMutation.isLoading ? true : false}
                                            onClick={() => {
                                                if (logininputs.email.length != 0 && logininputs.password.length != 0) {
                                                    logincheckMutation.mutate({
                                                        email: logininputs.email,
                                                        password: logininputs.password,
                                                    });
                                                }
                                            }}
                                        >
                                            <span
                                                class={logincheckMutation.isLoading ? generalstyles.fadeOutButton : generalstyles.fadeInButton + ' font-14 poppinslight '}
                                                style={{ letterSpacing: langdetect == 'en' ? '1px' : 0 }}
                                            >
                                                {lang.login}
                                            </span>
                                            <div class={logincheckMutation.isLoading ? generalstyles.fadeInButton + ' d-flex align-items-center justify-content-center ' : generalstyles.fadeOutButton}>
                                                <CircularProgress color="#fff" width="20px" height="20px" duration="1s" />
                                            </div>
                                        </button>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center mt-4 mb-1">
                                        <p class="m-0 p-0 text-light poppinslight">{lang.donthaveaccount}</p>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
                                        <button
                                            class={
                                                `${generalstyles.btn} ${generalstyles.btn_primary} ${formstyles.btn_form}` +
                                                ' d-flex align-items-center justify-content-center m-auto w-sm-100 poppinslight '
                                            }
                                            style={{ letterSpacing: langdetect == 'en' ? '1px' : 0 }}
                                            type={'button'}
                                            onClick={() => {
                                                window.location.href = 'https://www.tabexseries.com/home/signup';
                                            }}
                                        >
                                            {lang.signup}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-easing="ease-in-out"
                    class="col-lg-6 col-md-6 d-md-none p-0"
                    style={{
                        background: langdetect == 'en' ? '' : 'white',
                    }}
                >
                    <div class="row m-0 w-100 pr-5 pt-5">
                        <div class="col-lg-12 p-0 allcentered">
                            <p class="m-0 p-0 text-info text-uppercase" style={{ fontSize: '20px', fontWeight: 500 }}>
                                {lang.yourmobileapp} {langdetect == 'en' ? '&' : 'و'} {lang.website}
                            </p>
                        </div>
                        <div class="col-lg-12 p-0 allcentered text-uppercase">
                            <p class="m-0 p-0 text-secondary poppinsitalic" style={{ fontSize: '22px', fontWeight: 600 }}>
                                {lang.inoneplace}
                            </p>
                        </div>
                        <div class="col-lg-12 d-flex justify-content-center mt-3">
                            <img src={imagebaselink + '/tabexserieswebsiteasset/signupasset2.png'} style={{ maxWidth: '70%', maxHeight: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
